import InsuranceCard from 'components/insuranceCard';
import LinkTo from 'components/linkTo';
import { SectionHeader } from 'components/sectionHeader';
import { productLinks } from 'constants/internalLinks';
import {
  extendedVerticalsLookup,
  verticalsLookup,
} from 'content/policy-links/products';
import type { VerticalType } from 'models/product';
import { useTranslation } from 'next-i18next';
import React from 'react';

import styles from './style.module.scss';

const PolicyLinkSection = ({
  policies,
  title,
}: {
  title?: string;
  policies: VerticalType[];
}) => {
  const { t } = useTranslation();

  const homepageVerticals = verticalsLookup(t);
  const extendedVerticals = extendedVerticalsLookup(t);

  const allVerticals = { ...homepageVerticals, ...extendedVerticals };

  const sectionTitle: string =
    title === 'Our products'
      ? t('component.policylink.ourproducts.title', 'Our products')
      : t('component.policylink.otherpolicies.title', 'Other policies');

  return (
    <div className="p-body">
      <SectionHeader title={sectionTitle} titleClass="p-h2" />
      <div className={`${styles.container} mt24`}>
        {policies.map((policy) => {
          const insurance = allVerticals[policy];
          const link = productLinks[policy];

          return (
            <LinkTo
              key={policy}
              href={link}
              aria-label={`Link to ${insurance.title}`}
            >
              <InsuranceCard
                img={{
                  src: insurance.icon,
                  alt: insurance.title,
                }}
                title={insurance.shortTitle || insurance.title}
                group={insurance.group}
                excerpt={insurance.excerpt}
              />
            </LinkTo>
          );
        })}
      </div>
    </div>
  );
};

export default PolicyLinkSection;
